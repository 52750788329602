
import Vue from "vue";

import http from "@/http";

import QRCode from "qrcode";

import MyPreview from "@/components/MyPreview.vue";

import { Garbage } from "@/interfaces";
import { Route } from "vue-router";

export interface DataType {
  garbage?: Garbage;
  src: string;
}

export default Vue.extend({
  components: {
    MyPreview
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/garbages/${to.params.id}`;

    const { data } = await http.get<Garbage>(url);

    const src = await QRCode.toDataURL(data.url);

    next((vm: DataType & Vue) => {
      vm.garbage = data;
      vm.src = src;
    });
  },
  data(): DataType {
    return {
      garbage: undefined,
      src: ""
    };
  },
  computed: {
    id(): number {
      const { id } = this.$route.params;

      return +id;
    },

    title(): string {
      if (this.garbage == null) {
        return "";
      }

      return `ゴミの出し方：${this.garbage.building.name}`;
    },
    content(): string {
      if (this.garbage == null) {
        return "";
      }

      return this.garbage.content;
    }
  },
  methods: {}
});
